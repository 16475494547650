<template>
  <b-card>
    <validation-observer ref="batchForm">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              :label="$t('batchCode')"
              label-for="batchCode"
            >
              <validation-provider
                  #default="{ errors }"
                  name="batchCode"
                  rules="required"
              >
                <b-form-input
                  id="register-batchCode"
                  v-model="batchCode"
                  :placeholder="$t('batchCode')"
                  :state="errors.length > 0 ? false:null"
                  name="batchCode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                :label="$t('batchName')"
              label-for="batchName"
            >
              <validation-provider
                  #default="{ errors }"
                  name="batchName"
                  rules="required"
              >
                <b-form-input
                  id="register-batchName"
                  :state="errors.length > 0 ? false:null"
                  v-model="batchName"
                  name="batchName"
                  :placeholder="$t('batchName')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="6">
            <b-form-group
                :label="$t('batchStatus')"
                label-for="batchStatus"
            >
              <validation-provider
                  #default="{ errors }"
                  name="batchStatus"
                  rules="required"
              >
                <b-form-input
                    id="register-batchStatus"
                    :state="errors.length > 0 ? false:null"
                    v-model="batchStatus"
                    name="batchStatus"
                    :placeholder="$t('batchStatus')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
                :label="$t('productName')"
                label-for="productName"
            >
              <validation-provider
                  #default="{ errors }"
                  name="productName"
                  rules="required"
              >
                <b-form-input
                    id="register-productName"
                    :state="errors.length > 0 ? false:null"
                    v-model="productName"
                    name="productName"
                    :placeholder="$t('productName')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
              :label="$t('batchDescription')"
              label-for="batchDescription"
            >
              <validation-provider
                  #default="{ errors }"
                  name="batchDescription"
                  rules="required"
              >
                <b-form-input
                  id="register-batchDescription"
                  :state="errors.length > 0 ? false:null"
                  v-model="batchDescription"
                  name="batchDescription"
                  :placeholder="$t('batchDescription')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
                :label="$t('batchQuantity')"
                label-for="batchQuantity"
            >
              <validation-provider
                  #default="{ errors }"
                  name="batchQuantity"
                  rules="required"
              >
                <b-form-input
                    id="register-batchQuantity"
                    :state="errors.length > 0 ? false:null"
                    v-model="batchQuantity"
                    name="batchQuantity"
                    :placeholder="$t('batchQuantity')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>




          <b-col sm="6">
            <b-form-group
                :label="$t('start_date')"
                label-for="start_date"
            >
              <validation-provider
                  #default="{ errors }"
                  name="start_date"
                  rules="required"
              >
                <flat-pickr
                    id="register-start_date"
                    :state="errors.length > 0 ? false:null"
                    v-model="start_date"
                    class="form-control"
                    name="start_date"
                    :placeholder="$t('start_date')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
                :label="$t('end_date')"
                label-for="end_date"
            >
              <validation-provider
                  #default="{ errors }"
                  name="end_date"
                  rules="required"
              >
                <flat-pickr
                    id="register-end_date"
                    :state="errors.length > 0 ? false:null"
                    v-model="end_date"
                    class="form-control"
                    name="end_date"
                    :placeholder="$t('end_date')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
                :label="$t('percentage')"
                label-for="percentage"
            >
              <validation-provider
                  #default="{ errors }"
                  name="percentage"
                  rules="required"
              >
                <b-form-input
                    id="register-percentage"
                    :state="errors.length > 0 ? false:null"
                    v-model="percentage"
                    name="percentage"
                    :placeholder="$t('percentage')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col sm="6">
            <b-form-group
                :label="$t('actualManager')"
                label-for="actualManager"
            >
              <validation-provider
                  #default="{ errors }"
                  name="actualManager"
                  rules="required"
              >
                <b-form-select
                    id="register-actualManager"
                    :state="errors.length > 0 ? false:null"
                    v-model="actualManager"
                    name="actualManager"
                    :options="generalData"
                    :placeholder="$t('actualManager')"
                    :selected = "actualManager"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>




          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('Cancel') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, phoneNumber, integer } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import { BFormSelect } from 'bootstrap-vue'

export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    flatPickr,
    ValidationProvider,
    ValidationObserver

  },
  directives: {
    Ripple,
  },
  props: {
    generalData:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      batchCode:"",
      batchName:"",
      batchDescription:"",
      batchQuantity: "",
      start_date: "",
      end_date: "4000-01-01T00:00:00.001Z",
      batchStatus: String("初始化"),
      percentage: Number(0),
      actualManager:"",
      productName:"",
      optionsLocal: this.generalData,

      // validation
      required,
      email,
      phoneNumber
    }
  },
  methods: {
    validationForm() {
        this.$refs.batchForm.validate().then(success => {
          if (success) {
            useJwt.postData("tracking/batch/create/",{
              batchCode: this.batchCode,
              batchName: this.batchName,
              batchDescription: this.batchDescription,
              batchQuantity: this.batchQuantity,
              start_date: this.start_date + "T00:00:00.001Z",
              end_date: "4000-01-01T00:00:00.001Z" ,
              batchStatus: "Created",
              percentage: this.percentage,
              actualManager:this.actualManager,
              groupName: "菲乐兰集团",
              productName: this.productName,
            })
                .then(response => {
                  this.$router.push('/apps/batch/list')
                })
                .catch(error => {
                  this.$refs.registerForm.setErrors(error.response.data.error)
                })
          }
        })
    },
  },
  setup() {


    const { t } = useI18nUtils()
    return {
      t
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
