import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

const { t } = useI18nUtils()

export default function useShippingList() {
  // Use toast
  const toast = useToast()
  const refShippingListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'clientName', label: t('clientName'), sortable: true },
    { key: 'representative', label: t('representative'), sortable: true},
    { key: 'phoneNumber', label:t('phoneNumber'), sortable: true },
    { key: 'productName', label:t('productName'), sortable: true},
    { key: 'price', label:t('price'), sortable: true },
    { key: 'quantity', label:t('quantity'), sortable: true },
    { key: 'total',label: t('total'), sortable: true },
    { key: 'transferDate', label:t('transferDate'), sortable: true },
    { key: 'paymentDate', label:t('paymentDate'), sortable: true },
    { key: 'actions', label:t('actions') },
  ]

  const perPage = ref(10)
  const totalShipping = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const productFilter = ref(null)
  const startFilter = ref(null)
  const endFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refShippingListTable.value ? refShippingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalShipping.value,
    }
  })

  const resolveShippingProductVariant = productName => {
    if (productName === '猪饲料') return '猪饲料'
    if (productName === 'author') return 'warning'
    if (productName === 'maintainer') return 'success'
    if (productName === 'editor') return 'info'
    if (productName === 'admin') return 'danger'
    return '猪饲料'
  }

  const resolveShippingProductIcon = productName => {
    if (productName === '猪饲料') return '猪饲料'
    if (productName === 'author') return 'SettingsIcon'
    if (productName === 'maintainer') return 'DatabaseIcon'
    if (productName === 'editor') return 'Edit2Icon'
    if (productName === 'admin') return 'ServerIcon'
    return '猪饲料'
  }


  const refetchData = () => {
    refShippingListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, productFilter, startFilter, endFilter], () => {
    refetchData()
  })

  const fetchShippings = (ctx, callback) => {
    store
      .dispatch('app-shipping/fetchShippings', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        productName: productFilter.value,
        start: startFilter.value,
        end: endFilter.value,
      })
      .then(response => {
        console.log(response)
        const shipping = response.data
        callback(shipping)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  const resolveShippingStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }


  return {
    fetchShippings,
    tableColumns,
    perPage,
    currentPage,
    totalShipping,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refShippingListTable,
    resolveShippingProductVariant,
    resolveShippingProductIcon,
    resolveShippingStatusVariant,
    refetchData,
    // Extra Filters
    productFilter,
    startFilter,
    endFilter,
  }
}
