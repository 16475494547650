<template>
  <b-sidebar
    id="add-new-shipping-sidebar"
    :visible="isAddNewShippingSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-shipping-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Shipping') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
              #default="validationContext"
              :name="$t('clientName')"
              rules="required"
          >
            <b-form-group
                :label="$t('clientName')"
                label-for="clientName"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="shippingData.clientName"
                  :options="options.clientOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="shipping-clientName"
                  @input = "updateClient($event)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Representative -->
          <validation-provider
              #default="validationContext"
              :name="$t('representative')"
          >
            <b-form-group
                :label="$t('representative')"
                label-for="representative"
            >
              <b-form-input
                  id="representative"
                  v-model="shippingData.representative"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>

          <!-- PhoneNumber -->
          <validation-provider
              #default="validationContext"
              :name="$t('phoneNumber')"
          >
            <b-form-group
                :label="$t('phoneNumber')"
                label-for="phoneNumber"
            >
              <b-form-input
                  id="PhoneNumber"
                  v-model="shippingData.phoneNumber"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('productName')"
              rules="required"
          >
            <b-form-group
                :label="$t('productName')"
                label-for="productName"
                :state="getValidationState(validationContext)"
            >
              <v-select
                  v-model="shippingData.productName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options.productOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="shipping-productName"
                  @input = "updateProduct($event)"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <!-- Price -->
          <validation-provider
              #default="validationContext"
              :name="$t('price')"
          >
            <b-form-group
                :label="$t('price')"
                label-for="price"
            >
              <b-form-input
                  id="price"
                  v-model="shippingData.price"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>

          <!-- quantity -->
          <validation-provider
              #default="validationContext"
              :name="$t('quantity')"
          >
            <b-form-group
                :label="$t('quantity')"
                label-for="quantity"
            >
              <b-form-input
                  id="quantity"
                  v-model="shippingData.quantity"
                  :state="getValidationState(validationContext)"
                  trim
                  @input = "updateTotal($event)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
              #default="validationContext"
              :name="$t('total')"
          >
            <b-form-group
                :label="$t('total')"
                label-for="total"
            >
              <b-form-input
                  id="total"
                  v-model="shippingData.total"
                  :state="getValidationState(validationContext)"
                  trim
                  @input = "updateTotal($event)"
              />
            </b-form-group>
          </validation-provider>


          <b-form-group>
            <b-form-checkbox
                id="payed"
                v-model="shippingData.payed"
                name="checkbox-1"
                value ="1"
                unchecked-value ="0"
            >
              {{ $t('it has been payed') }}
            </b-form-checkbox>
          </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'


export default {
  components: {
    BFormCheckbox,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUShippingSidebarActive',
    event: 'update:is-add-new-shipping-sidebar-active',
  },
  props: {
    isAddNewShippingSidebarActive: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    clientReference: {
      type: Object,
      default: () => {
        return {}
      }
    },
    productReference: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const blankShippingData = {
      clientName: '',
      representative: '',
      phoneNumber: '',
      productName: '',
      price: null,
      quantity: null,
      total: null,
      payed: false,
      idClient: '',
      idProduct: '',
    }
    const shippingData = ref(JSON.parse(JSON.stringify(blankShippingData)))
    const resetshippingData = () => {
      shippingData.value = JSON.parse(JSON.stringify(blankShippingData))
    }

    const onSubmit = () => {
      store.dispatch('app-shipping/addShipping', shippingData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-shipping-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetshippingData)

    return {
      shippingData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods : {
    updateClient(event) {
      this.shippingData.phoneNumber = this.options["clientReference"][this.shippingData.clientName]['phoneNumber']
      this.shippingData.representative = this.options["clientReference"][this.shippingData.clientName]['representative']
      this.shippingData.idClient = this.options["clientReference"][this.shippingData.clientName]['idClient']
    },
    updateProduct(event) {
      this.shippingData.price = this.options["productReference"][this.shippingData.productName]['price']
      this.shippingData.idProduct = this.options["productReference"][this.shippingData.productName]['idProduct']
    },
    updateTotal(event) {
      this.shippingData.total = this.shippingData.price * this.shippingData.quantity
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-shipping-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
