<template>
  <b-card>
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
              :label="$t('clientName')"
              label-for="clientName"
          >
            <b-form-input
                v-model="shippingData.clientName"
                :placeholder="$t('clientName')"
                name="clientName"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              :label="$t('phoneNumber')"
              label-for="phoneNumber"
          >
            <b-form-input
                v-model="shippingData.phoneNumber"
                name="phoneNumber"
                :placeholder="$t('phoneNumber')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
              :label="$t('batchDescription')"
              label-for="batchDescription"
          >
            <b-form-input
                v-model="shippingData.productName"
                name="productName"
                :placeholder="$t('productName')"
            />

          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('price')"
              label-for="price"
          >
            <b-form-input
                v-model="shippingData.price"
                name="price"
                :placeholder="$t('price')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('quantity')"
              label-for="quantity"
          >
            <b-form-input
                v-model="shippingData.quantity"
                name="price"
                :placeholder="$t('quantity')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('total')"
              label-for="price"
          >
            <b-form-input
                v-model="shippingData.total"
                name="total"
                :placeholder="$t('total')"
                disabled ="disabled"
            />
          </b-form-group>
        </b-col>

        <!-- start date -->
        <b-col md="6">
          <b-form-group
              label-for="transferDate"
              :label="$t('transferDate')"
          >
            <flat-pickr
                v-model="shippingData.transferDate"
                :config = "pickerConfig"
                class="form-control"
                name="transferDate"
                :placeholder="$t('transferDate')"
            />
          </b-form-group>
        </b-col>

        <!-- end date -->
        <b-col md="6">
          <b-form-group
              label-for="paymentDate"
              :label="$t('paymentDate')"
          >
            <flat-pickr
                v-model="shippingData.paymentDate"
                :config = "pickerConfig"
                class="form-control"
                name="paymentDate"
                :placeholder="$t('paymentDate')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('representative')"
              label-for="representative"
          >
            <b-form-input
                v-model="shippingData.representative"
                name="representative"
                :placeholder="$t('representative')"
            />
          </b-form-group>
        </b-col>

        <b-col sm="6">
          <b-form-group
              :label="$t('wechatNumber')"
              label-for="wechatNumber"
          >
            <b-form-input
                v-model="shippingData.wechatNumber"
                name="wechatNumber"
                :placeholder="$t('wechatNumber')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="updateForm"
          >
            Save changes
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>


<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import shippingList from '../shipping-list/shippingList'
import useJwt from '@/auth/jwt/useJwt'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    flatPickr,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    shippingData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    updateForm() {
      useJwt.putData("shipping/history/update/" + this.shippingData.historyCode +'/', {
        clientName: this.shippingData.clientName,
        phoneNumber: this.shippingData.phoneNumber,
        productName: this.shippingData.productName,
        price: this.shippingData.price,
        quantity: this.shippingData.quantity,
        total: this.shippingData.total,
        transferDate: this.shippingData.transferDate,
        paymentDate: this.shippingData.paymentDate,
        representative: this.shippingData.representative,
        wechatNumber: this.shippingData.wechatNumber
      }).then(response => {
        this.$router.push('/apps/shipping/list/')
      })
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.shippingData))
    }
  },
  data() {
    return{
      pickerConfig: {
        enableTime: true,
        dateFormat: "Y-m-dTH:i"
      },
    }
  },

  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const { resolveUserRoleVariant } = shippingList()
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    const { t } = useI18nUtils()
    return {
      t,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
