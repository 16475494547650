
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  
  getters: {},
  mutations: {},
  actions: {
    fetchShippings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        useJwt
          .getData('shipping/history/create/?action=shipping&productname=' + queryParams['productName']
            + '&start=' + queryParams['start'] + '&end=' + queryParams['end'] )
          .then(
            response => {
              resolve(response)
            })
          .catch(error => reject(error))
      })
    },
    Options(){
      return new Promise((resolve, reject) => {
        useJwt
          .getData('shipping/history/create/?action=options')
          .then(
          response => {
            resolve(response)
          })
        .catch(error => reject(error))
      })
    },
    fetchShipping(ctx, { id }) {
      return new Promise((resolve, reject) => {
        useJwt
          .getData(`shipping/history/${id}`+'/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addShipping(ctx, userData) {
      return new Promise((resolve, reject) => {
        console.log(userData)
        useJwt
          .postData('shipping/history/create/?action=shipping', userData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
