<template>
  <b-sidebar
    id="add-new-product-sidebar"
    :visible="isAddNewProductSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-product-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Product') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Productname -->
          <validation-provider
            #default="validationContext"
            :name="$t('productName')"
            rules="required"
          >
            <b-form-group
              :label="$t('productName')"
              label-for="productName"
            >
              <b-form-input
                id="productName"
                v-model="productData.productName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- representative -->
          <validation-provider
              #default="validationContext"
              :name="$t('price')"
              rules="required"
          >
            <b-form-group
                :label="$t('price')"
                label-for="representative"
            >
              <b-form-input
                  id="representative"
                  v-model="productData.price"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- PhoneNumber -->
          <validation-provider
              #default="validationContext"
              :name="$t('brand')"
          >
            <b-form-group
                :label="$t('brand')"
                label-for="brand"
            >
              <b-form-input
                  id="brand"
                  v-model="productData.brand"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>
          <!-- address -->
          <validation-provider
              #default="validationContext"
              :name="$t('contact')"
          >
            <b-form-group
                :label="$t('contact')"
                label-for="contact"
            >
              <b-form-input
                  id="contact"
                  v-model="productData.contact"
                  :state="getValidationState(validationContext)"
                  trim
              />

            </b-form-group>
          </validation-provider>
          <!-- Email -->
          <validation-provider
              #default="validationContext"
              :name="$t('category')"
          >
            <b-form-group
                :label="$t('category')"
                label-for="category"
            >
              <b-form-input
                  id="category"
                  v-model="productData.category"
                  :state="getValidationState(validationContext)"
                  trim
              />


            </b-form-group>
          </validation-provider>


          <!-- address -->
          <validation-provider
              #default="validationContext"
              :name="$t('productDescription')"
          >
            <b-form-group
                :label="$t('productDescription')"
                label-for="productDescription"
            >
              <b-form-input
                  id="contact"
                  v-model="productData.productDescription"
                  :state="getValidationState(validationContext)"
                  trim
              />

            </b-form-group>
          </validation-provider>



          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewProductSidebarActive',
    event: 'update:is-add-new-product-sidebar-active',
  },
  props: {
    isAddNewProductSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const blankProductData = {
      productName: '',
      price: '',
      category: '',
      contact: '',
      brand: '',
      productDescription: '',
    }

    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const onSubmit = () => {
      store.dispatch('app-product/addProduct', productData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-product-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetproductData)

    return {
      productData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-product-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
