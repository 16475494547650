<template>

  <div>

    <shipping-list-add-new
        v-if="options"
        :is-add-new-shipping-sidebar-active.sync="isAddNewShippingSidebarActive"
        :options="options"
        @refetch-data="refetchData"
    />

    <!-- Filters -->
    <shipping-list-filters
      v-if="options"
      :product-filter.sync="productFilter"
      :start-filter.sync="startFilter"
      :end-filter.sync="endFilter"
      :product-options="options"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Search...')"
              />
              <b-button
                variant="primary"
                @click="isAddNewShippingSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('Add Shipping') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refShippingListTable"
        class="position-relative"
        :items="fetchShippings"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(shipping)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveShippingProductVariant(data.item.historyCode)}`"
                :to="{ name: 'apps-shipping-view', params: { id: data.item.historyCode } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-shipping-view', params: { id: data.item.historyCode } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.historyCode }}
            </b-link>
            <small class="text-muted">@{{ data.item.historyCode }}</small>
          </b-media>
        </template>

        <template #cell(productName)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveShippingProductIcon(data.item.productName)"
              size="18"
              class="mr-50"
              :class="`text-${resolveShippingProductVariant(data.item.productName)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.productName }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveShippingStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-shipping-view', params: { id: data.item.historyCode } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-shipping-edit', params: { id: data.item.historyCode } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalShipping"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import ShippingListFilters from './ShippingListFilters.vue'
import useShippingList from './shippingList'
import shippingStoreModule from '../shippingStoreModule'
import ShippingListAddNew from './ShippingListAddNew.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

export default {
  components: {
    ShippingListFilters,
    ShippingListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      options: {},
    }
  },
  async beforeCreate() {
    await useJwt.getData(`shipping/history/create/?action=options`).then(response => {
      this.options = response.data
    })
  },

  setup() {

    const { t } = useI18nUtils()
    const SHIPPING_APP_STORE_MODULE_NAME = 'app-shipping'

    // Register module
    if (!store.hasModule(SHIPPING_APP_STORE_MODULE_NAME)) store.registerModule(SHIPPING_APP_STORE_MODULE_NAME, shippingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SHIPPING_APP_STORE_MODULE_NAME)) store.unregisterModule(SHIPPING_APP_STORE_MODULE_NAME)
    })

    const isAddNewShippingSidebarActive = ref(false)


    const {
      fetchShippings,
      tableColumns,
      perPage,
      currentPage,
      totalShipping,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refShippingListTable,
      refetchData,

      // UI
      resolveShippingProductVariant,
      resolveShippingProductIcon,
      resolveShippingStatusVariant,
      // Extra Filters
      productFilter,
      startFilter,
        endFilter,

    } = useShippingList()

    return {
      t,
      // Sidebar
      isAddNewShippingSidebarActive,
      fetchShippings,
      tableColumns,
      perPage,
      currentPage,
      totalShipping,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refShippingListTable,
      refetchData,
      // Filter
      avatarText,


      // UI
      resolveShippingProductVariant,
      resolveShippingProductIcon,
      resolveShippingStatusVariant,

      // Extra Filters
      productFilter,
      startFilter,
      endFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
