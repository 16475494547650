<template>
  <component :is="shippingData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="shippingData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-shipping-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="shippingData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Shipping</span>
        </template>
        <shipping-edit-general
          :shipping-data="shippingData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import shippingStoreModule from '../shippingStoreModule'
import shippingEditGeneral  from '@/views/apps/shipping/shipping-edit/ShippingEditGeneral.vue'



export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    shippingEditGeneral
  },
  setup() {
    const shippingData = ref(null)

    const SHIPPING_APP_STORE_MODULE_NAME = 'app-shipping'

    // Register module
    if (!store.hasModule(SHIPPING_APP_STORE_MODULE_NAME)) store.registerModule(SHIPPING_APP_STORE_MODULE_NAME, shippingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SHIPPING_APP_STORE_MODULE_NAME)) store.unregisterModule(SHIPPING_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-shipping/fetchShipping', { id: router.currentRoute.params.id })
      .then(response => { shippingData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          shippingData.value = undefined
        }
      })

    return {
      shippingData,
    }
  },
}
</script>

<style>

</style>
