<template>
  <b-sidebar
    id="add-new-client-sidebar"
    :visible="isAddNewClientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-client-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Add New Client') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Clientname -->
          <validation-provider
            #default="validationContext"
            :name="$t('clientName')"
            rules="required"
          >
            <b-form-group
              :label="$t('clientName')"
              label-for="clientName"
            >
              <b-form-input
                id="clientName"
                v-model="clientData.clientName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- representative -->
          <validation-provider
              #default="validationContext"
              :name="$t('representative')"
              rules="required"
          >
            <b-form-group
                :label="$t('representative')"
                label-for="representative"
            >
              <b-form-input
                  id="representative"
                  v-model="clientData.representative"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- PhoneNumber -->
          <validation-provider
              #default="validationContext"
              :name="$t('phoneNumber')"
          >
            <b-form-group
                :label="$t('phoneNumber')"
                label-for="phoneNumber"
            >
              <b-form-input
                  id="PhoneNumber"
                  v-model="clientData.phoneNumber"
                  :state="getValidationState(validationContext)"
                  trim
              />
            </b-form-group>
          </validation-provider>


          <!-- address -->
          <validation-provider
              #default="validationContext"
              :name="$t('address')"
          >
            <b-form-group
                :label="$t('address')"
                label-for="address"
            >
              <b-form-input
                  id="address"
                  v-model="clientData.address"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="validationContext"
              :name="$t('email')"
          >
            <b-form-group
                :label="$t('email')"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="clientData.email"
                  :state="getValidationState(validationContext)"
                  trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- wechatNumber -->
          <validation-provider
              #default="validationContext"
              :name="$t('wechatNumber')"
          >
            <b-form-group
                :label="$t('wechatNumber')"
                label-for="wechatNumber"
            >
              <b-form-input
                  id="email"
                  v-model="clientData.wechatNumber"
                  :state="getValidationState(validationContext)"
                  trim
              />

            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { useUtils as useI18nUtils } from '@core/libs/i18n'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewClientSidebarActive',
    event: 'update:is-add-new-client-sidebar-active',
  },
  props: {
    isAddNewClientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18nUtils()
    const blankClientData = {
      clientName: '',
      email: '',
      representative: '',
      phoneNumber: '',
      wechatNumber: '',
      address: '',
    }

    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)))
    const resetclientData = () => {
      clientData.value = JSON.parse(JSON.stringify(blankClientData))
    }

    const onSubmit = () => {
      store.dispatch('app-client/addClient', clientData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-client-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclientData)

    return {
      clientData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-client-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
