<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('Filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('productName') }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="productFilter"
            :options="productOptions.productOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:productFilter', val)"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>{{ $t('startDate') }}</label>
          <flat-pickr
              :value="startFilter"
              :config = "pickerConfig"
              class="form-control"
              :reduce="val => val.value"
              @input="(val) => $emit('update:startFilter', val)"
          />
        </b-col>
        <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>{{ $t('endDate') }}</label>
          <flat-pickr
              :value="endFilter"
              :config = "pickerConfig"
              class="form-control"
              :reduce="val => val.value"
              @input="(val) => $emit('update:endFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    productFilter: {
      type: [String, null],
      default: null,
    },
    startFilter: {
      type: [String, null],
      default: null,
    },
    endFilter: {
      type: [String, null],
      default: null,
    },
    productOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return{
      pickerConfig: {
        enableTime: true,
        dateFormat: "Y-m-dTH:i"
      },
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
